import '../components/reset.css';

import ErrorBoundary from '@honeybadger-io/react';
import { AppProps } from 'next/app';
import React from 'react';

import { withApollo } from '../lib/apollo';
import honeybadger from '../lib/honeybadger';

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  return (
    <ErrorBoundary honeybadger={honeybadger}>
      <>
        <Component {...pageProps} />
      </>
    </ErrorBoundary>
  );
};

export default withApollo()(App as any);

import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import fetch from 'isomorphic-unfetch';
import { NextPageContext } from 'next';

import generatedIntrospection from '../generated/introspectionResult';
import honeybadger from './honeybadger';

export default function createApolloClient(
  initialState: NormalizedCacheObject,
  ctx?: NextPageContext
): ApolloClient<NormalizedCacheObject> {
  let headers = {
    'x-api-key': process.env.PUBLIC_FEDERATION_KEY,
  };

  if (ctx?.req?.headers) {
    const { host, ...rest } = ctx.req.headers;
    headers = {
      ...headers,
      ...rest,
    };
  }
  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.
  return new ApolloClient({
    ssrMode: Boolean(ctx),
    link: ApolloLink.from([
      setContext((_, previousContext) => {
        const { headers, slug, sessionToken } = previousContext;

        honeybadger.setContext({
          slug,
          headers,
        });
        return {
          ...previousContext,
          headers: {
            ...headers,
            'x-wedding-identifier': encodeURIComponent(slug),
            token: sessionToken,
          },
        };
      }),
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach((err) => {
            if (err.extensions && err.extensions.code !== 'PIN_REQUIRED') {
              honeybadger.notify(err, 'GraphQLError', { context: { err } });
            }
          });
        }

        if (networkError && (networkError as any).statusCode !== 404) {
          honeybadger.notify(networkError, 'NetworkError');
        }
      }),
      new HttpLink({
        uri: process.env.PUBLIC_FEDERATION_URL, // Server URL (must be absolute)
        fetch,
        headers,
      }),
    ]),
    cache: new InMemoryCache({
      possibleTypes: generatedIntrospection.possibleTypes,
    }).restore(initialState),
  });
}

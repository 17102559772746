
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "WWS_WeddingWebsiteResult": [
      "WWS_Error",
      "WWS_WeddingWebsiteV2"
    ],
    "WWS_PageItem": [
      "WWS_AccommodationItem",
      "WWS_ActivityItem",
      "WWS_BasicItem",
      "WWS_CoverPhotoItem",
      "WWS_EmbedItem",
      "WWS_EventGroupItem",
      "WWS_GifItem",
      "WWS_GroupItem",
      "WWS_HeadlineItem",
      "WWS_LivestreamItem",
      "WWS_ParagraphItem",
      "WWS_PersonItem",
      "WWS_PhotoGalleryItem",
      "WWS_PhotoItem",
      "WWS_PhotoTimelineItem",
      "WWS_QuestionItem",
      "WWS_StoryItem",
      "WWS_TransportationItem",
      "WWS_WeddingPartyItem"
    ],
    "WWS_SectionContent": [
      "WWS_SectionItem",
      "WWS_Story",
      "WWS_Event",
      "WWS_ContentItem",
      "WWS_LocationContent",
      "WWS_PhotoAlbum"
    ]
  }
};
      export default result;
    